import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/720p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "720p CGI Commands",
  "path": "/720p_Series_CGI_List/Alarm_Menu/Alarm_Server/",
  "dateChanged": "2018-03-26",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_720p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "720p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='720p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_720p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/720p_Serie_CGI_Befehle/Alarm_Menu/Alarm_Server/' locationFR='/fr/720p_Series_CGI_List/Alarm_Menu/Alarm_Server/' crumbLabel="720p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "the-alarm-menu--alarm-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-alarm-menu--alarm-server",
        "aria-label": "the alarm menu  alarm server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Alarm Menu :: Alarm Server`}</h2>
    <br /><br />
    <hr></hr>
    <h3 {...{
      "id": "paramcgicmdsetmdalarm-anameserver2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetmdalarm-anameserver2",
        "aria-label": "paramcgicmdsetmdalarm anameserver2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setmdalarm-aname=server2`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Activate the 3rd Alarmserver to send HTTP GET Requests`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`aname:`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`switch`}</code>{`: `}{`[on, off]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Return`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success, Error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only the 3rd Alarmserver is availble - Others might be used for other purposes and should not be reconfigured! You can check the other Alarmserver with the GET command above.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getmdalarm&-aname=server2`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setmdalarm&-aname=server2&-switch=on`}</code></td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <h3 {...{
      "id": "paramcgicmdsetalarmserverattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetalarmserverattr",
        "aria-label": "paramcgicmdsetalarmserverattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setalarmserverattr`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Configure the 3rd Alarmserver`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_server`}</code>{`: `}{`[IP or Domain]`}{` Adress of your server`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_port`}</code>{`: `}{`[1-65536]`}{` Serverport`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_path`}</code>{`: `}{`[String]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_query1`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`as_query2`}</code>{`: Add a attributes & value to your URL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_queryattr1`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`as_queryattr2`}</code>{`: Attribute of your variable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_queryval1`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`as_queryval2`}</code>{`: Value of your variable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_auth`}</code>{`: `}{`[0, 1]`}{` Activate/Deactivate Authentification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`as_username`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`as_password`}</code>{` User login`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Return`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success, Error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setalarmserverattr&-as_index=3&-as_server=192.168.1.114&-as_port=8999&-as_path=%2FPath%2FTo%2FScripts-as_query1=1&-as_queryattr1=ir&-as_queryval1=on&-as_query2=1&-as_queryattr2=ptz&-as_queryval2=2&-as_auth=1&-as_username=admin&-as_password=admin`}</code></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      